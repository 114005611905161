import React from "react";

import "./styles.css";

function MusicItem({ data }) {
  return (
    <div className="musicContentContainer">
      <div className="musicContentSongInfo">
        <div className="musicContentSongInfoArtistImage">
          <img src={data?.artist?.photo} />
        </div>
        <div className="musicContentSongInfo2">
          <div className="musicContentSongName">
            <b>{data?.song?.name}</b>
            <span>{data?.song?.timestamp}</span>
          </div>
          <span>{data?.artist?.name}</span>
        </div>
      </div>
      <a
        className="musicContentSongStream"
        href={data?.song?.streamLink}
        target="_blank"
      >
        <span>Stream</span>
      </a>
    </div>
  );
}

export default MusicItem;
