import { Link, useLocation } from "react-router-dom";
import "./styles.css";

const Logo = require("../../assets/logo.png");

function Header({ showOptions, handlePress, handleLastRelease }) {
  return (
    <>
      <header className="headerContainer">
        <div className="headerContent01">
          <a href="/">
            <img src={Logo} alt="Smell Records Logo" />
          </a>
          <span>Bringing out the best feelings in you.</span>
          <div className="headerSocialsContent">
            <a
              className="headerSocialsItems"
              href="https://instagram.com/smellrecords"
              target="_blank"
            >
              <box-icon type="logo" name="instagram-alt" />
            </a>
            <a
              className="headerSocialsItems"
              href="https://twitter.com/smellrecords"
              target="_blank"
            >
              <box-icon
                className="headerSocialsItems"
                name="twitter"
                type="logo"
              />
            </a>
            <a
              className="headerSocialsItems"
              href="https://facebook.com/smellrecords"
              target="_blank"
            >
              <box-icon name="facebook-circle" type="logo" />
            </a>
          </div>
          <div className="headerMenuIcon" onClick={() => handlePress(true)}>
            <box-icon name="menu" />
          </div>
        </div>

        <Content02 handlePress={handlePress} />
      </header>
      {showOptions && (
        <div className="mobileHeaderContainer">
          <div className="mobileHeaderItemsContent">
            <ul>
              <li
                onClick={() => {
                  handlePress(false);
                  handleLastRelease(true);
                }}
              >
                <Link to="/">Home</Link>
              </li>
              <li
                onClick={() => {
                  handlePress(false);
                  handleLastRelease(false);
                }}
              >
                <Link to="/whoweare">Who We Are</Link>
              </li>
              <li
                onClick={() => {
                  handlePress(false);
                  handleLastRelease(false);
                }}
              >
                <Link to="contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

function Content02() {
  const { pathname } = useLocation();

  return (
    <div className="headerContent02">
      <Link to="/">
        <span
          className="headerContent02Item"
          style={{
            fontWeight: pathname == "/" ? 600 : 400,
          }}
        >
          Releases
        </span>
      </Link>
      <Link to="/whoweare">
        <span
          className="headerContent02Item"
          style={{
            fontWeight: pathname == "/whoweare" ? 600 : 400,
          }}
        >
          Who we are
        </span>
      </Link>
      <Link to="/contact">
        <span
          className="headerContent02Item"
          style={{
            fontWeight: pathname == "/contact" ? 600 : 400,
          }}
        >
          Contact
        </span>
      </Link>
    </div>
  );
}

export default Header;
