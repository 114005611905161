import { useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import "./styles.css";

import Header from "../../components/Header";
import MusicItem from "../../components/MusicItem";

import data from "../../config/data.json";
import { Helmet } from "react-helmet-async";

function Home(props) {
  const [showOptions, setShowOptions] = useState(false);
  const [showLastRelease, setLastRelease] = useState(true);

  return (
    <BrowserRouter>
      <div className="mainContainer">
        <Header
          showOptions={showOptions}
          handlePress={(value) => setShowOptions(value)}
          handleLastRelease={(value) => setLastRelease(value)}
        />
        <main className="mainContent">
          {showLastRelease && (
            <div className="lastReleaseContent">
              <div className="lastReleaseSongName">
                <h1>RHUNYM</h1>
                <h2>SCAR</h2>
              </div>
              <a href={data.releases[0].song.streamLink} target="_blank">
                <div className="lastReleaseStreamButton">
                  <box-icon name="play-circle" color="#ffffff" />
                  <span>Stream now</span>
                </div>
              </a>
            </div>
          )}
          <div className="releasesContent">
            <Routes>
              <Route path="/" element={<ReleaseContent />} />
              <Route path="/whoweare" element={<WhoWeAre />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </main>
      </div>
    </BrowserRouter>
  );
}

const ReleaseContent = () => {
  return (
    <>
      <Helmet>
        <title>Smell Records | Bringing out the best feelings in you.</title>
      </Helmet>
      <span className="releasesContentItemTitle  releasesContentItemTitleBold">
        Recent Releases
      </span>
      {data.releases.map((item) => (
        <MusicItem key={item.id} data={item} />
      ))}
      <span className="releasesContentItemTitle">Popular Releases</span>
      {data.popularReleases.map((item) => (
        <MusicItem key={item.id} data={item} />
      ))}
    </>
  );
};

const WhoWeAre = () => {
  const fullYear = new Date().getFullYear();
  return (
    <>
      <Helmet>
        <title>Who we are</title>
      </Helmet>
      <h1 className="sectionsTitle">Who we are</h1>

      <p className="sectionsContent">
        Founded on <i>11/24/2020</i>, a Brazilian independent record label, with
        the mission of making small foreign artists recognized and appreciated.
        Focused on digital releases for platforms such as: Spotify, Youtube
        Music, Itunes and Deezer. We are passionate about music, especially
        Future Bass, Trap, Dance & EDM music styles. We are here to bring out
        the best feelings in every beat.
        <br />
        <br />
        If you are an artist and would like to get in touch with us, click on
        the <Link to="/contact">contact</Link> tab.
        <br />
        <br />
        @SmellRecords {fullYear}
      </p>
    </>
  );
};

const Contact = () => {
  const fullYear = new Date().getFullYear();

  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <h1 className="sectionsTitle">Contact</h1>
      <p className="sectionsContent">
        Daily Smell Records is looking for amazing artists like you. To send
        your demo or contact us just access the email below or through our
        social media. Feel free to send us your demos and stories, we are here
        to listen to you.
        <br />
        <br />
        <span>
          E-mail: <address>smellrecordsofficial@gmail.com</address>
        </span>
        <br />
        <br />
        @SmellRecords {fullYear}
      </p>
    </>
  );
};

export default Home;
